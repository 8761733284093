export default function blankPage(router, sign) {
    // sign为了防止路由第一层layout(主页)设置为BlankPage(空白页);
    sign++;
    router.forEach((item) => {
        if (item.children && item.children.length > 0) {
            if (sign > 1) {
                item.component = "BlankPage";
            }
            blankPage(item.children, sign);
        }
    });
};