import { http } from "../../request.js";
function getUserInfo() {
  let data = {};
  sessionStorage.getItem("userInfo")
    ? (data = JSON.parse(sessionStorage.getItem("userInfo")))
    : (data = {});
  return data;
}
function getTypeId() {
  return `${getUserInfo().type}Id`;
  // switch (getUserInfo().type) {
  //   case "chain":
  //    return  data.chainId = getUserInfo().id;
  //     break;
  //   case "region":
  //     data.regionId = getUserInfo().id;
  //     break;
  //   default:
  // }
}
export default {
  //图片上传
  uploadImg(data) {
    return http.uploadFile("/file/upload", data);
  },
  //批量添加药店
  batchPharmacy(data) {
    return http.uploadFile("/admin/batchImportAdmin", data);
  },

  // 获取连锁下拉
  selectChainList(data) {
    return http.postForm("/admin/selectChainList", data);
  },
  // 获取大区下拉
  selectRegionList(data) {
    data[getTypeId()] = getUserInfo().id;
    // console.log(getUserInfo());
    return http.postForm("/admin/selectRegionList", data);
  },
  // 获取片区下拉
  selectAreaList(data) {
    data[getTypeId()] = getUserInfo().id;
    return http.postForm("/admin/selectAreaList", data);
  },
  // 获取药店下拉
  selectPharmacyList(data) {
    data[getTypeId()] = getUserInfo().id;
    return http.postForm("/admin/selectPharmacyList", data);
  },
  // 获取地区下拉
  getAddressByPid(data) {
    return http.postForm("/address/queryAddressByPid", data);
  },
  /**
   * @片区管理
   **/
  //获取片区列表
  queryAreaList(data) {
    data.id = getUserInfo().id;
    data.type = 3;
    data.adminType = "area";
    return http.post("/admin/queryAdminList", data);
  },
  //通过id获取片区详情
  queryAreaById(data) {
    data.adminType = "area";
    return http.postForm("/admin/queryAdminById", data);
  },
  //添加片区
  addArea(data) {
    data.id = getUserInfo().id;
    data.adminType = "area";
    return http.post("/admin/addAdmin", data);
  },
  //修改片区
  editArea(data) {
    data.adminType = "area";
    return http.post("/admin/updateAdmin", data);
  },
  //修改账号状态
  updateStatus(data) {
    data.adminType = "area";
    return http.postForm("/admin/updateStatus", data);
  },
  /**
   * @药店管理
   **/
  //获取药店列表
  queryPharmacyList(data) {
    data.id = getUserInfo().id;
    data.type = 4;
    data.adminType = "pharmacy";
    return http.post("/admin/queryAdminList", data);
  },
  //通过id获取药店详情
  queryPharmacyById(data) {
    data.adminType = "pharmacy";
    return http.postForm("/admin/queryAdminById", data);
  },
  //添加药店
  addPharmacy(data) {
    data.id = getUserInfo().id;
    data.adminType = "pharmacy";
    return http.post("/admin/addAdmin", data);
  },
  //修改药店
  editPharmacy(data) {
    data.adminType = "pharmacy";
    return http.post("/admin/updateAdmin", data);
  },
  //修改账号状态
  updateStatus(data) {
    data.adminType = "pharmacy";
    return http.postForm("/admin/updateStatus", data);
  },
  /**
   * @数据管理
   **/
  //获取血糖数据列表
  sugarDataList(data) {
    data.id = getUserInfo().id;
    return http.post("/gluce/queryGlucemiaList", data);
  },
  //导出血糖数据列表
  sugarExcelList(data) {
    data.id = getUserInfo().id;
    return http.post("/gluce/exportGlucemiaList", data);
  },
  //获取血压数据列表
  pressureDataList(data) {
    data.id = getUserInfo().id;
    return http.post("/bloodPressure/queryBloodPressureList", data);
  },
  //导出血压数据列表
  pressureExcelList(data) {
    data.id = getUserInfo().id;
    return http.post("/bloodPressure/exportBloodPressureList", data);
  },
  //获取血脂数据列表
  fatDataList(data) {
    data.id = getUserInfo().id;
    return http.post("/bloodFat/queryBloodFatList", data);
  },
  //导出血脂数据列表
  fatExcelList(data) {
    data.id = getUserInfo().id;
    return http.post("/bloodFat/exportBloodFatList", data);
  },
  //获取血酮数据列表
  ketoneDataList(data) {
    data.id = getUserInfo().id;
    return http.post("/bk/queryBkList", data);
  },
  //导出血酮数据列表
  ketoneExcelList(data) {
    data.id = getUserInfo().id;
    return http.post("/bk/exportBkList", data);
  },
  //获取血尿酸数据列表
  uricAcidDataList(data) {
    data.id = getUserInfo().id;
    return http.post("/bua/queryBuaList", data);
  },
  //导出血尿酸数据列表
  uricAcidExcelList(data) {
    data.id = getUserInfo().id;
    return http.post("/bua/exportBuaList", data);
  },
  //获取心电数据列表
  heartDataList(data) {
    data.id = getUserInfo().id;
    return http.post("/ecg/queryEcgList", data);
  },
  //导出心电数据列表
  heartExcelList(data) {
    data.id = getUserInfo().id;
    return http.post("/ecg/exportEcgList", data);
  },
  //获取心电详情
  queryEcgDetails(data) {
    // data.id = getUserInfo().id;
    return http.postForm("/ecg/queryEcgDetails", data);
  },
  //获取患者列表
  queryUserList(data) {
    data.id = getUserInfo().id;
    return http.post("/user/queryUserList", data);
  },
  //分页查询用户测量数据详情列表
  queryUserDetailsList(data) {
    return http.post("/user/queryUserDetailsList", data);
  },
  //查询用户测量数据统计图
  queryUserDetailChart(data) {
    return http.post("/user/queryUserDetailsById", data);
  },
  //获取患者基本信息
  queryUserById(data) {
    // data.id = getUserInfo().id;
    return http.postForm("/user/queryUserById", data);
  },
  // 首页顶部
  getGlucemiaList(data) {
    data.id = getUserInfo().id;
    return http.post("/pc/homePage/getGlucemiaList", data);
  },
  // 高血糖列表
  getGlucemiaListHigh(data) {
    data.id = getUserInfo().id;
    return http.post("/pc/homePage/hyperglycemiaList", data);
  },
  // 低血糖列表
  getGlucemiaListLow(data) {
    data.id = getUserInfo().id;
    return http.post("/pc/homePage/hypoglycemiaList", data);
  },
  // 首页血压
  getBloodPressureList(data) {
    data.id = getUserInfo().id;
    return http.post("/pc/homePage/getBloodPressureList", data);
  },
  // 首页血脂
  getBloodFatList(data) {
    data.id = getUserInfo().id;
    return http.post("/pc/homePage/getBloodFatList", data);
  },
  // 首页血酮
  getBloodBkList(data) {
    data.id = getUserInfo().id;
    return http.post("/pc/homePage/getBkList", data);
  },
  // 首页尿酸
  getUricAcidList(data) {
    data.id = getUserInfo().id;
    return http.post("/pc/homePage/getBuaList", data);
  },
  // 首页心电
  getEcgList(data) {
    data.id = getUserInfo().id;
    return http.post("/pc/homePage/getEcgList", data);
  },
  // 试条统计
  testStripList(data) {
    data.id = getUserInfo().id;
    return http.post(
      "/testStripUsageStatistics/queryTestStripUsageStatisticsList",
      data
    );
  },
  // 消息管理列表
  getMsgManageList(data) {
    data.adminId = getUserInfo().id;
    return http.post("/messageManagement/queryMessagePushList", data);
  },
  //消息推送
  batchPush(data) {
    data.id = getUserInfo().id;
    return http.postForm("/messageManagement/batchPush", data);
    // return http.post("/messageManagement/batchPush", data);
  },
  //修改消息内容
  editMsgContent(data) {
    // data.id = getUserInfo().id;
    return http.postForm("/messageManagement/updateMessagePush", data);
  },
  //删除消息内容
  delMsgContent(data) {
    // data.id = getUserInfo().id;
    return http.postForm("/messageManagement/deleteMessagePush", data);
  },
  //获取mq地址
  getMqUrl(data) {
    // data.id = getUserInfo().id;
    return http.postForm("/pharmacy/getMqUrl", data);
  },

  /**大数据 */
  //  大数据获取折线统计图
  screenLineChart(data) {
    data.id = getUserInfo().id;
    return http.postForm("/pc/homePage/dataStatisticsZxt", data);
  },
  //  大数据获取柱状统计图
  screenColumnarChart(data) {
    data.id = getUserInfo().id;
    return http.postForm("/pc/homePage/dataStatisticsZzt", data);
  },
  //  大数据获取饼状图（右下角）
  screenPieChart(data) {
    data.id = getUserInfo().id;
    return http.postForm("/pc/homePage/chronicDiseaseTypeProportion", data);
  },
  //  大数据获取性别占比（左上角）
  screenGenderChart(data) {
    data.id = getUserInfo().id;
    return http.postForm("/pc/homePage/sexAgeProportion", data);
  },
  //  大数据实时监控（左上角）
  screenRealTime(data) {
    data.id = getUserInfo().id;
    return http.postForm("/pc/homePage/realTimeMonitoring", data);
  },
  //  大数据排名（右上角）
  screenRank(data) {
    data.id = getUserInfo().id;
    return http.postForm("/pc/homePage/statistics", data);
  },
  //  大数据测量情况（右上角）
  screenMeasurement(data) {
    data.id = getUserInfo().id;
    return http.postForm("/pc/homePage/measurementResult", data);
  },
  //大数据地图显示
  screenMap(data) {
    data.id = getUserInfo().id;
    return http.postForm("/pc/homePage/mapDisplay", data);
  }
};
