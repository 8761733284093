/*
 * @version: 1.0
 * @Date: 2021-10-18 12:48:34
 * @LastEditTime: 2023-07-05 10:59:15
 * @FilePath: \otc-coupon\src\http\api\login.js
 */
import { http } from "../request.js";

export default {
  //登录
  loginByPassword(data) {
    return http.post("/login", data);
  },
  //修改密码
  updateUserPassword(data) {
    return http.postForm("/changePassword", data);
  },

  // 测试数据
  testHttp(data) {
    return http.post("/bloodSugarPatientInfo/getAllDataInfo", data);
  },
  patientTarget(data) {
    return http.postForm("/bloodSugarPatientInfo/updatePatientTarget", data);
  },
  patientOutHospital(data) {
    return http.getForm(
      "/bloodSugarPatientInfo/updatePatientOutHospital",
      data
    );
  },
  getDataUserBaseInfoById(data) {
    return http.get(
      `/bloodSugarPatientInfo/getDataUserBaseInfoById/id/${data.id}`
    );
  }
};
