/*
 * @version: 1.0
 * @Date: 2021-10-20 13:57:43
 * @LastEditTime: 2021-10-20 13:57:43
 * @FilePath: \demo\src\store\modules\sidebar.js
 */
const state = {
  isCollapse: false,
}

const mutations = {
  SIDEBAR_ISCOllAPSE: (state, isCollapse) => {
        state.isCollapse = isCollapse;
        sessionStorage.setItem("isCollapse", JSON.stringify(state.isCollapse))
    },
}

const actions = {
    sidebar_iscollapse: ({ commit },isCollapse ) => {
        commit("SIDEBAR_ISCOllAPSE", isCollapse)
    },
}



export default {
    namespaced: true,
    state,
    mutations,
    actions
}