import trim from "./trim";


// const install = function (Vue) {
//   Vue.directive("trim",trim);
// };
// if (window.Vue) {
//   window["trim"] = trim;
//   Vue.use(install);
// }
export default trim;
