/*
 * @version: 1.0
 * @Date: 2022-03-31 15:46:15
 * @LastEditTime: 2022-03-31 15:46:16
 * @FilePath: \otc-coupon\src\common\conversion\bus.js
 */
import Vue from "vue";

export default new Vue(); //es6的写法
/**
 * 相当于下面这样写
 *
 * const bus = new Vue()
 * module.exports = bus
 */
