/*
 * @version: 1.0
 * @Date: 2021-10-18 15:33:35
 * @LastEditTime: 2022-03-24 15:42:05
 * @FilePath: \otc-coupon\src\store\modules\login.js
 */
import { constantRoutes } from "@/router";
// console.log(constantRoutes);
const state = {
  addRoutes: [],
  routes: [],
  token: "", //token
  routeID: [], //路由权限ID
  userInfo: {
    type: "",
    id: "",
    account: "",
    adminYichengId: ""
  }
};

const mutations = {
  USER_INFORMATION: (state, login) => {
    state.addRoutes = login;
    // console.log(state.addRoutes);
    state.routes = state.addRoutes.concat(constantRoutes);
    // console.log(state.routes);
    sessionStorage.setItem("routes", JSON.stringify(state.routes));
    sessionStorage.setItem("addRoutes", JSON.stringify(state.addRoutes));
  },
  USER_TOKEN: (state, token) => {
    state.token = token;
    sessionStorage.setItem("token", JSON.stringify(state.token));
  },
  USER_DEANID: (state, deanId) => {
    state.deanId = deanId;
    sessionStorage.setItem("deanId", JSON.stringify(state.deanId));
  },
  USER_ROUTEID: (state, id) => {
    state.routeID = id;
    sessionStorage.setItem("routeID", JSON.stringify(state.routeID));
  },
  USER_INFO: (state, userInfo) => {
    state.userInfo.type = userInfo.type;
    state.userInfo.id = userInfo.id;
    state.userInfo.account = userInfo.account;
    state.userInfo.adminYichengId = userInfo.adminYichengId;
    state.userInfo.adminRegionId = userInfo.adminRegionId
      ? userInfo.adminRegionId
      : "";
    sessionStorage.setItem("userInfo", JSON.stringify(state.userInfo));
  }
};

const actions = {
  user_information: ({ commit }, login) => {
    commit("USER_INFORMATION", login);
  },
  user_token: ({ commit }, token) => {
    commit("USER_TOKEN", token);
  },
  user_deanId: ({ commit }, deanId) => {
    commit("USER_DEANID", deanId);
  },
  user_routeid: ({ commit }, id) => {
    commit("USER_ROUTEID", id);
  },
  user_info: ({ commit }, userInfo) => {
    commit("USER_INFO", userInfo);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
