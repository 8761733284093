<template>
  <div class="header">
    <!-- <span class="switchState">
      <i class="iconfont icon-shouqi" @click="switchState">
        <span style="font-size:18px">收起</span>
      </i>
    </span> -->
    <div class="right">
      <el-dropdown
        @command="handleCommand"
        :hide-on-click="false"
        trigger="click"
        placement="top"
      >
        <span class="el-dropdown-link">
          <!-- <i class="iconfont icon-xitongguanliyuan"></i> -->
          {{ roleType }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="loginH5" v-if="userType != 'coupon_dev'"
            >移动端登录</el-dropdown-item
          > -->
          <el-dropdown-item command="changePwd">修改密码</el-dropdown-item>
          <el-dropdown-item command="signOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dialog
        title="修改密码"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
        center
      >
        <el-form
          class="changeForm"
          label-width="100"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="旧密码:" prop="oldPassword">
            <el-input v-trim v-model="ruleForm.oldPassword"></el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="newPassword">
            <el-input v-trim v-model="ruleForm.newPassword"></el-input>
          </el-form-item>
          <el-form-item label="二次确认:" prop="userNewPwd2">
            <el-input v-trim v-model="ruleForm.userNewPwd2"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sendParameter">确定</el-button>
          <el-button type="danger" @click="closeDialog">取 消</el-button>
        </div>
      </el-dialog>
      <ErCodeDialog></ErCodeDialog>
    </div>
  </div>
</template>

<script>
import "../sidebar/font/iconfont.css";
import ErCodeDialog from "@/components/erCodeDialog/index.vue";
import bus from "@/common/conversion/bus.js";
export default {
  data() {
    var validateNewPwd = (rule, value, callback, compare) => {
      if (value == this.ruleForm.oldPassword) {
        callback(new Error("与旧密码一致,请重新输入"));
      } else {
        callback();
      }
    };
    var validateNewPwd2 = (rule, value, callback, compare) => {
      if (value != this.ruleForm.newPassword) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: { oldPassword: "", newPassword: "", userNewPwd2: "" },
      dialogVisible: false,
      test: "",
      codeDialog: false, //移动端登录二维码
      userType: "",
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validateNewPwd, trigger: "blur", required: true }
        ],
        userNewPwd2: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          { validator: validateNewPwd2, trigger: "blur", required: true }
        ]
      }
    };
  },
  components: {
    ErCodeDialog
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    // 获取角色类型
    getUserInfo() {
      let data = {};
      sessionStorage.getItem("userInfo")
        ? (data = JSON.parse(sessionStorage.getItem("userInfo")))
        : (data = {});
      this.userType = data.type;
      // console.log(this.userType);
    },
    // 修改密码弹框X
    handleClose() {
      this.ruleForm = {};
      this.dialogVisible = false;
    },
    closeDialog() {
      this.ruleForm = {};
      this.dialogVisible = false;
    },

    // 修改密码提交
    sendParameter() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            id: sessionStorage.getItem("userId"),
            oldPassword: this.ruleForm.oldPassword,
            newPassword: this.ruleForm.newPassword
          };
          console.log(data);
          this.$api.login
            .updateUserPassword(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.ruleForm = {};
              this.dialogVisible = false;
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        } else {
          return false;
        }
      });

      // console.log(data);
    },
    handleCommand(command) {
      if (command == "signOut") {
        // alert("退出登录")
        this.$router.push({ path: "/login" });
        sessionStorage.clear();
      } else if (command == "changePwd") {
        this.dialogVisible = true;
      } else if (command == "loginH5") {
        this.codeDialog = true;
        bus.$emit("codeDialog", this.codeDialog);
      }
    },
    // 切换侧边栏的状态
    switchState() {
      let isCollapse = this.$store.state.sidebar.isCollapse;
      this.$store.dispatch("sidebar/sidebar_iscollapse", !isCollapse);
    }
  },
  computed: {
    roleType() {
      let userType = sessionStorage.getItem("userType");
      // return userInfo ? JSON.parse(userInfo).roleType : "";
      switch (userType) {
        case "chain":
          return "连锁端";
        case "yicheng":
          return "怡成端";
        case "region":
          return "大区端";
        case "area":
          return "片区端";
        case "pharmacy":
          return "药店端";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  color: #ffffff;
  background-color: #2a8df4;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // margin-bottom: 10px;
  .switchState {
    .icon-qiehuan {
      font-size: 25px;
      color: #666666;
    }
  }
  .right {
    padding-right: 20px;
    .el-dropdown {
      color: #ffffff;
    }
    .item {
      // margin-top: 10px;
      margin-right: 40px;
      font-size: 12px;
    }
    .changeForm {
      // background-color: red;
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        display: flex;
        align-items: center;
        ::v-deep .el-form-item__label {
          width: 100px;
        }
        .el-input {
          max-width: 400px;
          min-width: 300px;
        }
      }
    }
  }
}
</style>
