<!--
 * @version: 1.0
 * @Date: 2022-01-21 08:59:51
 * @LastEditTime: 2022-03-11 08:40:59
 * @FilePath: \otc-coupon\src\views\Layout\blankPage.vue
-->
<template>
  <div class="blankpage">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.blankpage {
  height: 100%;
  // width: 100vw;
  // background-color: red;
}
</style>
