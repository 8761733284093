import login from "./api/login.js";
import admin from "./api/health/admin.js";
import chain from "./api/health/chain.js";
import common from "./api/health/common.js";
const api = {
  login,
  admin,
  common,
  chain
};
// console.log(coupon);
// console.log(apiTest.login.testHttp);
export { api };
