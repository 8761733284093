<!--
 * @version: 1.0
 * @Date: 2021-10-20 14:55:24
 * @LastEditTime: 2023-10-26 13:35:57
 * @FilePath: \demo\src\views\sidebar\Item.vue
-->
<script>
// 阿里图标库
import "./font/iconfont.css";

export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  },
  render(h, context) {
    // console.log(context.props);
    const { icon, title } = context.props;
    const vnodes = [];
    // console.log(icon);

    if (icon) {
      if (icon.includes("el-icon")) {
        vnodes.push(<i class={[icon, icon]} />);
      } else {
        vnodes.push(<img src={[icon]} />);
      }
      // else {
      //   vnodes.push(<i class={["iconfont", icon, "alicon"]} />);
      // }
    }

    if (title) {
      vnodes.push(<span slot="title">{title}</span>);
    }
    return vnodes;
  }
};
</script>
<style lang="scss" scoped>
.el-menu-item i {
  color: #fff;
}
.el-menu-item.is-active i {
  &::before {
    color: #2a8df4;
  }
}

.el-submenu i {
  color: #fff;
}
.is-opened i {
  &::before {
    color: #fff;
  }
}
// .el-submenu__title i {
//   color: #fff;
// }
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}

.alicon {
  color: currentColor;
  width: 1em;
  height: 1em;
  font-size: 20px;
  margin-right: 10px;
}
</style>
<style></style>
