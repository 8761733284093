/*
 * @version: 1.0
 * @Date: 2021-10-22 08:59:21
 * @LastEditTime: 2021-11-01 10:50:44
 * @FilePath: \demo\src\common\conversion\recursiveRouter.js
 */
import layout from "../../views/Layout/index.vue";
import BlankPage from "../../views/Layout/blankPage.vue";

// 将后端传回的component 的字符串模式，改为我们前端路由需要的component模式
function loadPageByRoutes(str) {
  // console.log(str);

  // return (resolve) => require([`@/views/${str}`], resolve);
  return function (resolve) {
    require([`@/views${str}`], resolve);
  };
  // return (resolve) => require([`@/views/${str}`], resolve);
}

// 递归路由
export default function getAddRoutes(data) {
  data.forEach((item) => {
    if (item.component == "layout") {
      item.component = layout;
    } else if (item.component == "BlankPage") {
      item.component = BlankPage;
    } else {
      item.component = loadPageByRoutes(item.component);
    }
    if (item.children && item.children.length > 0) {
      getAddRoutes(item.children);
    }
  });
}
