/*
 * @version: 1.0
 * @Date: 2021-10-25 10:35:44
 * @LastEditTime: 2022-03-08 09:38:09
 * @FilePath: \otc-coupon\src\store\getters.js
 */
const getters = {
  permission_routes: (state) => {
    if (state.login.routes && state.login.routes.length > 0) {
      return state.login.routes;
    } else {
      let routes = sessionStorage.getItem("routes")
        ? JSON.parse(sessionStorage.getItem("routes"))
        : [];

      return routes;
    }
  }
};
export default getters;
