let baseURL = "";
const { VUE_APP_ENV_VARIABLE } = process.env;
if (VUE_APP_ENV_VARIABLE == "development") {
  //本地环境
  baseURL = "/api";
  // baseURL = 'https://test.yicheng120.com:981';//测试
  // baseURL = "https://mapi.yicheng120.com/yc-otc-coupon-test/coupon"; //线上
} else if (VUE_APP_ENV_VARIABLE == "test") {
  // baseURL = "https://test.yicheng120.com:981";
  baseURL = "https://test-health.yicheng1993.com:881/YC_OTC_Health";
} else if (VUE_APP_ENV_VARIABLE == "production") {
  baseURL = "https://health.yicheng1993.com/YC_OTC_Health";
}

export { baseURL };
