<!--
 * @version: 1.0
 * @Date: 2022-03-16 13:18:10
 * @LastEditTime: 2022-04-06 13:19:55
 * @FilePath: \otc-coupon\src\components\erCodeDialog\index.vue
-->
<template>
  <div>
    <el-dialog
      title="移动端登录地址"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div><qr-code :code="code"></qr-code> {{ code }}</div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="danger" @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QrCode from "@/components/QrCode.vue";
import bus from "@/common/conversion/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      code: "123456"
    };
  },
  components: {
    QrCode
  },
  mounted() {
    this.dis();
    this.getUserInfo();
    // console.log(this.getUserInfo().type);
  },
  methods: {
    // 获取角色类型
    getUserInfo() {
      let data = {};
      sessionStorage.getItem("userInfo")
        ? (data = JSON.parse(sessionStorage.getItem("userInfo")))
        : (data = {});
      return data;
    },
    dis() {
      bus.$on("codeDialog", (codeDialog) => {
        // console.log(codeDialog);
        this.dialogVisible = codeDialog;
      });
      // const { VUE_APP_ENV_VARIABLE } = process.env;
      const obj = {
        coupon_organ: "https://mapi.yicheng120.com/Plus/#/organs/login",
        coupon_zd: "https://mapi.yicheng120.com/Plus/#/generalAgent/login",
        coupon_dl: "https://mapi.yicheng120.com/Plus/#/agent/login"
      };

      this.code = obj[this.getUserInfo().type];
      // console.log(VUE_APP_ENV_VARIABLE);
    },
    handleClose(done) {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
