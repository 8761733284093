import { http } from "../../request.js";
function getUserInfo() {
  let data = {};
  sessionStorage.getItem("userInfo")
    ? (data = JSON.parse(sessionStorage.getItem("userInfo")))
    : (data = {});
  return data;
}

export default {
  //获取敏感词列表
  querySensitiveList(data) {
    // console.log(getUserInfo());
    // data.userId = getUserInfo().id;
    return http.post("/thesaurus/queryThesaurusList", data);
  },
  //批量导入敏感词
  batchAddThesaurus(data) {
    data.id = getUserInfo().id;
    return http.uploadFile("/thesaurus/batchAddThesaurus", data);
  },
  //添加敏感词
  addSensitive(data) {
    // console.log(getUserInfo());
    data.adminId = getUserInfo().id;
    return http.post("/thesaurus/addThesaurus", data);
  },
  //修改敏感词
  editSensitive(data) {
    // console.log(getUserInfo());
    data.adminId = getUserInfo().id;
    return http.post("/thesaurus/updateThesaurus", data);
  },
  //删除敏感词
  delSensitive(data) {
    // console.log(getUserInfo());
    data.adminId = getUserInfo().id;
    return http.post("/thesaurus/deleteThesaurus", data);
  },
  //获取文章库列表
  queryArticleList(data) {
    return http.post("/article/queryArticleList", data);
  },
  //通过id获取文章回显
  queryArticleById(data) {
    return http.postForm("/article/queryArticleById", data);
  },
  //修改文章状态
  changeArticleStatus(data) {
    return http.postForm("/article/updateArticleStatus", data);
  },
  //添加文章
  addArticle(data) {
    data.adminId = getUserInfo().id;
    return http.post("/article/addArticle", data);
  },
  //修改文章
  updateArticle(data) {
    data.adminId = getUserInfo().id;
    return http.post("/article/updateArticle", data);
  },
  //删除文章
  deleteArticle(data) {
    data.adminId = getUserInfo().id;
    return http.post("/article/deleteArticle", data);
  },

  /**
   * @设备管理
   **/
  //获取未分配设备列表
  queryEquipmentList(data) {
    return http.post("/equipment/queryEquipmentList", data);
  },
  //获取已分配设备列表
  queryEquipmentYfpList(data) {
    return http.post("/equipment/queryEquipmentYfpList", data);
  },
  //添加设备
  addEquipment(data) {
    data.adminId = getUserInfo().id;
    return http.post("/equipment/addEquipment", data);
  },
  //修改设备
  updateEquipment(data) {
    data.adminId = getUserInfo().id;
    return http.post("/equipment/updateEquipment", data);
  },
  //删除设备
  deleteEquipment(data) {
    // data.adminId = getUserInfo().id;
    return http.postForm("/equipment/deleteEquipment", data);
  },
  //解绑设备
  unbindEquipment(data) {
    // data.adminId = getUserInfo().id;
    return http.postForm("/equipment/unbind", data);
  },
  //取消分配设备
  cancelAllocation(data) {
    // data.adminId = getUserInfo().id;
    return http.postForm("/equipment/cancelAllocation", data);
  },
  //分配设备
  allocationEquipment(data) {
    data.adminId = getUserInfo().id;
    return http.post("equipment/allocationEquipment", data);
  },
  /**
   * @设备型号管理
   **/
  //获取设备型号列表
  queryDeviceList(data) {
    return http.post("/device/queryDeviceList", data);
  },
  //通过id获取设备型号详情
  queryDeviceById(data) {
    return http.postForm("/device/queryDeviceById", data);
  },
  // 获取蓝牙型号下拉
  getBluetoothList(data) {
    return http.getForm("/deviceBluetooth/queryType", data);
  },
  //添加设备型号
  addDevice(data) {
    return http.post("/device/addDevice", data);
  },
  //编辑设备型号
  updateDevice(data) {
    return http.post("/device/updateDevice", data);
  },
  //删除设备型号
  deleteDevice(data) {
    return http.postForm("/device/deleteDevice", data);
  },
  /**
   * @连锁管理
   **/
  //获取连锁列表
  queryChainList(data) {
    data.id = getUserInfo().id;
    data.type = 1;
    data.adminType = "chain";
    return http.post("/admin/queryAdminList", data);
  },
  //通过id获取连锁详情
  queryAdminById(data) {
    data.adminType = "chain";
    return http.postForm("/admin/queryAdminById", data);
  },
  //修改账号状态
  updateStatus(data) {
    data.adminType = "chain";
    return http.postForm("/admin/updateStatus", data);
  },
  //添加连锁
  addChain(data) {
    data.id = getUserInfo().id;
    data.adminType = "chain";
    return http.post("/admin/addAdmin", data);
  },
  //修改连锁
  editChain(data) {
    data.adminType = "chain";
    return http.post("/admin/updateAdmin", data);
  },
  /**
   * @用户管理
   **/
  //用户管理列表查询
  queryUserList(data) {
    data.id = getUserInfo().id;
    data.adminType = "chain";
    return http.post("/user/queryUserList", data);
  }
};
