<!--
 * @version: 1.0
 * @Date: 2021-10-20 10:51:25
 * @LastEditTime: 2023-08-31 09:01:02
 * @FilePath: \otc-coupon\src\views\Layout\index.vue
-->
<template>
  <el-container class="home">
    <el-aside class="Sidebar" :class="isCollapse ? 'closeSidebar' : ''">
      <!-- <el-aside class="Sidebar"> -->
      <v-sidebar></v-sidebar>
    </el-aside>
    <el-container class="main">
      <el-main>
        <v-header></v-header>
        <router-view :key="key" class="load-on-enter"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import vHeader from "./header";
import vSidebar from "../sidebar/index.vue";
export default {
  data() {
    return {
      isActive: false
    };
  },
  mounted() {
    this.isActive = true;
  },
  components: {
    vSidebar,
    vHeader
  },
  computed: {
    key() {
      return this.$route.path;
    },
    isCollapse() {
      return this.$store.state.sidebar.isCollapse;
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  .el-aside {
    width: 220px !important;
    height: 100%;
    background-color: #2A8DF4 ;
  }
  .closeSidebar {
    width: 60px !important;
  }
  .el-container.main {
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: #f5f5f5;
    .el-main{
      padding: 0;
    }
  }
}
</style>
