<template>
  <!-- <div :class="{'has-logo':showLogo}"> -->
  <div>
    <div class="header-title">
      <img src="../../assets/common/title.png" alt="" class="title-img" />
      <span> 怡成健康</span>
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        :collapse="isCollapse"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import variables from "@/styles/_variables.scss";

export default {
  data() {
    return {};
  },

  components: { SidebarItem },
  computed: {
    ...mapGetters(["permission_routes"]),
    activeMenu() {
      return this.$route.meta.guidePath
        ? this.$route.meta.jumpPath
        : this.$route.path;
      // const route = this.$route;
      // const { meta, path } = route;

      // if (meta.activeMenu) {
      //   return meta.activeMenu;
      // }
      // return path;
    },

    variables() {
      return variables;
    },
    isCollapse() {
      return this.$store.state.sidebar.isCollapse;
    }
  },
  // data() {
  //   return {
  //     // isCollapse: false
  //   };
  // },
  mounted() {
    // console.log(this.permission_routes);
  }
};
</script>

<style scoped lang="scss">
.header-title {
  background-color: #2a8df4;
  display: flex;
  align-items: center;
  padding: 16px;
  // border-bottom: 6px #F6F6F6 solid;
  // display: flex;
  .title-img {
    // display: block;
    width: 24px;
    height: 24px;
  }
  span {
    padding-left: 17px;
    // width: 80px;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 28px;
  }
}
.el-menu {
  border: 0;
  ::v-deep a {
    text-decoration: none;
  }
  ::v-deep .el-menu-item:hover {
    // background: #FFFFFF !important;
  }
  ::v-deep .el-menu-item.is-active {
    background: #ffffff !important;
  }
  ::v-deep .el-submenu__title:hover {
    // color: #2e95fb !important;
    // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
  }
  // ::v-deep .el-icon {
  //   color: #ffffff;
  // }
}
</style>
