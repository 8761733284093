import axios from "axios";
import qs from "qs";
import router from "@/router";
import store from "../store/index.js";
import { baseURL } from "./setBaseURL.js";
import { Message } from "element-ui";

const instance = axios.create({
  baseURL: baseURL,
  timeout: 10000
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const token =
      store.state.token || JSON.parse(sessionStorage.getItem("token"));
    // console.log(config.url);
    // if (token) {
    // 过滤taken
    if (config.url != "/login") {
      config.headers.common["Authorization"] = token;
    }
    // }
    config.headers.common["ClientType"] = "PC";
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    if (response.status == 200) {
      if (response.data.code == 9999) {
        router.push("/login");

        // Message.error({
        //   duration: 500,
        //   title: "提示",
        //   position: "top-left",
        //   message: "登录失效正在跳转登录认证页...",
        //   onClose: () => {
        //     // 解决token失效F5刷新回到登录页登录页面不刷新携带上次Token访问的问题
        //     let Timeout = setTimeout(() => {
        //       window.location.reload();
        //     });
        //     Timeout = null;
        //   }
        // });
      }
      return response.data;
    }
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
// // 设置请求类型
// instance.defaults.headers['Content-Type'] = 'application/json'
// 获取token
function setToke() {
  // alert(11)
  const token =
    store.state.token || JSON.parse(sessionStorage.getItem("token"));
  // const token = JSON.parse(sessionStorage.getItem("token"))
  instance.interceptors.request.use(
    (config) => {
      // alert(token)
      if (token) {
        // 过滤taken
        // console.log(config);
        if (config.url != "/login") {
          config.headers.common["Authorization"] = token;
        }
      }
      return config;
    },
    (error) => {
      // 对请求错误做些什么
      Toast({
        message: error,
        position: "center"
      });
      return Promise.reject(error);
    }
  );
}

const http = {
  // form表单格式提交
  getForm(url, data) {
    // console.log(baseURL);
    return new Promise(function (resolve, reject) {
      instance(url, {
        method: "get",
        // 'X-Requested-With': 'XMLHttpRequest'
        headers: { "content-type": "application/x-www-form-urlencoded" },
        params: data
      })
        .then((res) => {
          if (res.code == 200 || res.code == 0) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
          reject(err);
        });
    });
  },
  // 路径格式提交
  get(url) {
    return new Promise(function (resolve, reject) {
      instance(url, {
        method: "get",
        headers: { "content-type": "application/x-www-form-urlencoded" }
      })
        .then((res) => {
          if (res.code == 200 || res.code == 0) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
          reject(err);
        });
    });
  },
  // json格式提交
  post(url, data) {
    return new Promise(function (resolve, reject) {
      instance(url, {
        method: "post",
        headers: { "content-type": "application/json" },
        data
      })
        .then((res) => {
          if (res.code == 200 || res.code == 0) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
          reject(err);
        });
    });
  },
  // form表单格式提交
  postForm(url, data) {
    return new Promise(function (resolve, reject) {
      instance(url, {
        method: "post",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data)
      })
        .then((res) => {
          if (res.code == 200 || res.code == 0) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
          reject(err);
        });
    });
  },
  // formData文件格式上传
  uploadFile(url, data) {
    return new Promise(function (resolve, reject) {
      if (data.formData == "json") {
        data = qs.stringify(data);
      } else {
        let keys = Object.keys(data);
        let formData = new FormData();
        keys.forEach((item) => {
          if (item != "type" && item != "requestType" && item != "format") {
            formData.append(item, data[item]);
          }
        });

        data = formData;
      }

      instance(url, {
        method: "post",
        headers: {
          "content-type": data.requestType
            ? data.requestType
            : "multipart/form-data"
        },
        data
      })
        .then((res) => {
          if (res.code == 200 || res.code == 0) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
          reject(err);
        });
    });
  }
};

export { http, setToke };
