/*
 * @Descripttion: 怡成健康开发
 * @version: 1.0.0
 * @Author: 赵子龙
 * @Date: 2023-06-27 09:16:17
 * @LastEditors: 赵子龙
 * @LastEditTime: 2023-07-27 09:48:52
 */
export default function getRouter() {
    return new Promise((resolve) => {
        let accessedRoutes = JSON.parse(sessionStorage.getItem("addRoutes"));
        resolve(accessedRoutes);
    });
};
