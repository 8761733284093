/*
 * @version: 1.0
 * @Date: 2021-10-28 13:48:52
 * @LastEditTime: 2023-10-19 15:31:37
 * @FilePath: \otc-coupon\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// ElementUI.Dialog.props.closeOnPressEscape.default = false;
Vue.use(ElementUI);
import directive from "./directive";
Vue.use(directive);
// 大数据用的插件
import dataV from "@jiaminghi/data-view";

Vue.use(dataV);
import { api } from "@/http/index"; //网络请求
Vue.prototype.$api = api;

Vue.config.productionTip = false;

// const whiteList = ["/login"];
// router.beforeEach((to, from, next) => {
//   // alert(1111);
//   /* 必须调用 `next` */
//   // console.log(to);

//   if (token) {
//     next();
//   } else {
//     if (whiteList.indexOf(to.path) !== -1) {
//       next();
//     } else {
//       console.log(1111);
//       next({ path: "/" });
//     }
//   }
// });
// console.log(11111);
// function isAuthenticated() {
//   return sessionStorage.getItem("token");

// }
// 用来测试如果以后有多个登录页的情况
const whiteList = ["/login", "/privacyAgreementDetail"];
//路由守卫
router.beforeEach((to, from, next) => {
  if (to.path == "/login") sessionStorage.clear();
  const isAuthenticated = sessionStorage.getItem("token");
  // console.log(whiteList.indexOf(to.path));
  if (whiteList.indexOf(to.path) < 0 && !isAuthenticated) {
    next({ name: "Login" });
  } else {
    next();
  }
});

// 全局样式css
// import "./styles/index.scss"

new Vue({  router, store, render: (h) => h(App) }).$mount("#app");
