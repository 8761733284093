<!--
 * @Descripttion: 怡成健康开发
 * @version: 1.0.0
 * @Author: Z
 * @Date: 2023-06-27 09:16:17
 * @LastEditors: Z
 * @LastEditTime: 2023-09-06 16:41:38
-->

<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import { setHtmlFontSize } from "@/common";
import {
  getRouter,
  getAddRoutes,
  blankPage
} from "../src/common/conversion/index.js";

export default {
  name: "App",

  data() {
    return {
      // isRouterAlive: true,
    };
  },
  methods: {
    // 取到缓存路由,进行转换并添加实现刷新不丢失路由
    // refreshSave() {

    //   let routes = [];
    //   getRouter().then((res) => {
    //     // console.log(111);
    //     // console.log(res);
    //     // routes = res;
    //     if (routes && routes.length > 0) {
    //       // console.log(111);
    //       blankPage(routes, 0);
    //       getAddRoutes(routes);
    //       // routes.forEach((item) => {
    //       //   // console.log(item);
    //       //   // console.log(this.$router);
    //       //   this.$router.addRoute(item);
    //       // });
    //     }
    //   });

    // },

    async refreshSave() {
      let routes = [];
      await getRouter().then((res) => {
        routes = res;
      });

      if (routes && routes.length > 0) {
        // console.log(routes);
        await blankPage(routes, 0);
        await getAddRoutes(routes);
        routes.forEach((item) => {
          // console.log(item.component());
          this.$router.addRoute(item);
        });
      }
    }
  },
  mounted() {
    // 路由转换
    this.refreshSave();

    // setHtmlFontSize();

    window.addEventListener("resize", this.resizeHtmlFontSize);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.resizeHtmlFontSize);
    });
  }
};
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>

