<!--
 * @version: 1.0
 * @Date: 2022-03-14 12:58:03
 * @LastEditTime: 2022-03-16 13:35:21
 * @FilePath: \otc-coupon\src\components\QrCode.vue
-->
<template>
  <div class="generate-coupon-code">
    <div id="coupon-code" class="coupon-code"></div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  props: {
    code: {
      type: String,
      required: true
    },
    colorDark: {
      type: String,
      default: () => "#45A2FF"
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      this.generateCode();
    });
  },
  methods: {
    // 生成二维码
    generateCode() {
      // let size = document.documentElement.style.fontSize;

      // size = size.split("px")[0] * 2.6;
      new QRCode("coupon-code", {
        width: 100,
        height: 100, // 高度
        text: this.code, // 二维码内容
        colorDark: this.colorDark
        // colorLight: "#45A2FF"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.generate-coupon-code {
  .coupon-code {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
