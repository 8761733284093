

import { http } from "../../request.js";
function getUserInfo() {
  let data = {};
  sessionStorage.getItem("userInfo")
    ? (data = JSON.parse(sessionStorage.getItem("userInfo")))
    : (data = {});
  return data;
}

export default {
  //获取连锁侧边栏两个标题
  getChainTitle(data) {
    // console.log(getUserInfo());
    data.adminChainId = getUserInfo().id;
    return http.postForm("/admin/queryChainTitle", data);
  },
  //修改连锁侧边栏两个标题
  updateChainTitle(data) {
    // console.log(getUserInfo());
    data.adminChainId = getUserInfo().id;
    return http.postForm("/admin/updateChainTitle", data);
  },
  //获取知识库列表
  queryKnowledgeBaseList(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/knowledgeBase/queryKnowledgeBaseList", data);
  },
  //通过id获取知识库回显
  queryKnowledgeBaseById(data) {
    return http.postForm("/knowledgeBase/queryKnowledgeBaseById", data);
  },
  //修改知识库状态
  changeKnowledgeBaseStatus(data) {
    return http.postForm("/knowledgeBase/updateStatus", data);
  },
  //添加知识库
  addKnowledgeBase(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/knowledgeBase/addKnowledgeBase", data);
  },
  //修改知识库
  updateKnowledgeBase(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/knowledgeBase/updateKnowledgeBase", data);
  },
  //删除知识库
  deleteKnowledgeBase(data) {
    data.adminChainId = getUserInfo().id;
    return http.postForm("/knowledgeBase/deleteKnowledgeBase", data);
  },
  //获取慢病列表
  queryChronicDiseaseZoneList(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/chronicDiseaseZone/queryChronicDiseaseZoneList", data);
  },
  //通过id获取慢病回显
  queryChronicDiseaseZoneById(data) {
    return http.postForm("/chronicDiseaseZone/queryChronicDiseaseZoneById", data);
  },
  //修改慢病状态
  changeChronicDiseaseStatus(data) {
    return http.postForm("chronicDiseaseZone/updateStatus", data);
  },
  //添加慢病
  addChronicDiseaseZone(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/chronicDiseaseZone/addChronicDiseaseZone", data);
  },
  //修改慢病
  updateChronicDiseaseZone(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/chronicDiseaseZone/updateChronicDiseaseZone", data);
  },
  //删除慢病
  deleteChronicDiseaseZone(data) {
    data.adminChainId = getUserInfo().id;
    return http.postForm("/chronicDiseaseZone/deleteChronicDiseaseZone", data);
  },
  //血糖统计
  statisticsSugar(data) {
    data.chainId = getUserInfo().id;
    return http.post("/gluce/statisticsGlucemia", data);
  },
  //血压统计
  statisticsPressure(data) {
    data.chainId = getUserInfo().id;
    return http.post("/bloodPressure/statisticsBloodPressure", data);
  },
  /**
   * @大区管理
   **/
  //获取大区列表
  queryRegionList(data) {
    data.id = getUserInfo().id;
    data.type =2;
    data.adminType = "region";
    return http.post("/admin/queryAdminList", data);
  },
  //通过id获取大区详情
  queryRegionById(data) {
    data.adminType ="region";
    return http.postForm("/admin/queryAdminById", data);
  },
  //添加大区
  addRegion(data) {
    data.id = getUserInfo().id;
    data.adminType ="region";
    return http.post("/admin/addAdmin", data);
  },
  //修改大区
  editRegion(data) {
    data.adminType ="region";
    return http.post("/admin/updateAdmin", data);
  },
  //修改账号状态
  updateStatus(data) {
    data.adminType = "region";
    return http.postForm("/admin/updateStatus", data);
  },

   /**
   * @热销榜分类管理
   **/
  //获取热销榜分类列表
  queryHotListTypeList(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/hotListProductType/queryHotListProductTypeList", data);
  },
  //通过id获取热销榜分类详情
  queryHotListTypeById(data) {
    data.adminChainId = getUserInfo().id;
    return http.postForm("/hotListProductType/queryHotListProductTypeById", data);
  },
  //添加热销榜分类
  addHotListType(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/hotListProductType/addHotListProductType", data);
  },
  //修改热销榜分类
  updateHotListType(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/hotListProductType/updateHotListProductType", data);
  },
   //修改热销榜状态
   changeHotListTypeStatus(data) {
    data.adminChainId = getUserInfo().id;
    return http.postForm("/hotListProductType/hotListProductTypeStatus", data);
  },
   /**
   * @热销商品管理
   **/
  //获取热销商品列表
  getHotListProductList(data) {
    // data.id = getUserInfo().id;
    return http.post("/hotListProduct/getHotListProductList", data);
  },
  //通过id获取热销商品详情
  getHotListProductById(data) {
    return http.postForm("/hotListProduct/getHotListProductById", data);
  },
  //添加热销商品
  addHotListProduct(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/hotListProduct/addHotListProduct", data);
  },
  //修改热销商品
  updateHotListProduct(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/hotListProduct/updateHotListProduct", data);
  },
  //删除热销商品
  deleteHotListProduct(data) {
    data.adminChainId = getUserInfo().id;
    return http.post("/hotListProduct/deleteHotListProduct", data);
  },

};
